import {
  ClipboardDocumentListIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline"
import React, { Component } from "react"
import Button from "../../components/base/Button"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import Hero from "../../components/marketing/Hero"
import LeftContentCard from "../../components/marketing/LeftContentCard"
import RightContentCard from "../../components/marketing/RightContentCard"
import SubscribeAction from "../../components/marketing/SubscribeAction"
import Whatsapp from "../../components/marketing/Whatsapp"
import AboutMPOB from "../../images/about_mbop.webp"
import JMBHome from "../../images/jmb_home.jpg"
import JMBOil from "../../images/jmb_oilsink.png"
import JMBTransaction from "../../images/jmb_transaction.png"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

class JmbInitiative extends Component {
  renderMaterialContent = () => {
    const language = Language.BM
    return (
      <>
        <p className="mt-4 text-base leading-7 text-gray-500 whitespace-pre-line">
          {localeContent(language).jmbInitiative.jmbContent.jmbDescription3}
        </p>
        <dl className="mt-10 space-y-10">
          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                <ClipboardDocumentListIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                {localeContent(language).jmbInitiative.jmbContent.jmbHeader1}
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500 text-justify">
              {localeContent(language).jmbInitiative.jmbContent.jmbCaption1}
            </dd>
          </div>
          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                {localeContent(language).jmbInitiative.jmbContent.jmbHeader2}
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500 text-justify">
              {localeContent(language).jmbInitiative.jmbContent.jmbCaption2}
            </dd>
          </div>
        </dl>
      </>
    )
  }

  renderContactSections() {
    const language = Language.BM
    return (
      <>
        <div className="max-w-md mx-auto px-4 sm:px-6 sm:max-w-3xl lg:max-w-7xl">
          <div className="divide-y-2 divide-gray-200">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div className="flex-col">
                <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  {
                    localeContent(language).jmbInitiative.jmbGetInTouch
                      .jmbTitle4
                  }
                </h2>
                <div className="mt-3 text-base text-gray-500 w-4/5 text-justify">
                  {localeContent(language).jmbInitiative.jmbGetInTouch.jmbDesc}
                </div>
              </div>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {
                      localeContent(language).jmbInitiative.jmbGetInTouch
                        .jmbSmallHeader1
                    }
                  </h3>
                  <dl className="mt-2 text-base text-gray-500 whitespace-pre-line">
                    <div>
                      <dd>
                        {
                          "IOI Rio Office Tower \n Bandar Puteri Puchong, 47100 \n Puchong, Selangor."
                        }
                      </dd>
                    </div>
                    <div className="mt-1">
                      <dd>info@arusoil.com</dd>
                    </div>
                    <div className="mt-1">
                      <dd>+60-137311007</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const language = Language.BM
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Kempen Mengitar Semula Bersama JMB Malaysia"
          description="Arus Oil terlibat secara aktif dengan badan pengurusan bersama atau pengurusan hartanah di Selangor, Pulau Pinang dan negeri lain di Malaysia untuk mewujudkan kesedaran tentang mengitar semula minyak masak terpakai melalui kempen promosi."
        />
        <Header page={"Inisiatif JMB"} />
        <Hero
          title={
            localeContent(language).jmbInitiative.jmbTopTitle.jmbTitleBlack
          }
          content={
            localeContent(language).jmbInitiative.jmbTopTitle.jmbDescription
          }
          image={JMBHome}
          callToAction={
            <div className="mt-4 sm:mt-0">
              <a
                target="_blank"
                href={"../../../file/arus-booklet-edm-01-22.pdf"}
                download="arus-booklet-edm.pdf"
                rel="noreferrer"
              >
                <Button
                  className="w-full"
                  type="normal"
                  text={
                    localeContent(language).jmbInitiative.jmbTopTitle
                      .jmbGetStarted
                  }
                />
              </a>
            </div>
          }
          language={language}
          size={"small"}
        />
        <RightContentCard
          contain={true}
          title={localeContent(language).jmbInitiative.jmbTopTitle.jmbTitle2}
          content={
            localeContent(language).jmbInitiative.jmbTopTitle.jmbDescription2
          }
          image={JMBOil}
        />
        <LeftContentCard
          title={localeContent(language).jmbInitiative.jmbContent.jmbTitle3}
          content={this.renderMaterialContent()}
          image={JMBTransaction}
          contain={true}
        />
        <RightContentCard
          contain={true}
          image={AboutMPOB}
          title="Pengendali Berlesen - Arus Oil​"
          content={
            "Lesen MPOB diperlukan untuk mengumpulkan / membeli minyak masak terpakai. Minyak masak terpakai (Kelapa Sawit) dikelaskan sebagai minyak kelapa sawit (SPO) di bawah Peraturan Perlesenan Lembaga Minyak Sawit Malaysia 2005.\n\n Arus Oil adalah pengendali berlesen di bawah MPOB dengan nombor lesen kami sebagai 619709017000. Beroperasi dengan lesen MPOB memastikan semua minyak masak terpakai yang dikumpulkan ditukar kepada biodiesel dan bukan untuk penggunaan haram yang akan membahayakan masyarakat dan alam sekitar."
          }
        />
        {this.renderContactSections()}
        <SubscribeAction language={language} />
        <Footer />
      </div>
    )
  }
}

export default JmbInitiative
